import StandardMenuImgBgIntro from "./themes/landingMain/IntroImageBg";
import FullScreenHeader from "./themes/landingMain/HeaderFullScreenNav";

import AboutUs from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
/* import Testimonials from "./Testimonials"; */
import Contact from "./Contact";

const LandingMain = () => {

    return (
        <>
            

                <FullScreenHeader textWhite={true}></FullScreenHeader>
                <StandardMenuImgBgIntro></StandardMenuImgBgIntro>
                <div id="content" role="main" className="bg-light">
                <AboutUs></AboutUs>
                <Services></Services>
                <Portfolio></Portfolio>
{/*                 <Testimonials></Testimonials> */}
                <Contact></Contact>

                 </div>

        </>
    )

};

export default LandingMain;