import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section bg-white">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
        <span className="shaddowletter ">Ü</span><span className="shaddowletter">B</span><span className="shaddowletter">E</span><span className="shaddowletter">R</span><span className="shaddowletter">&nbsp;</span><span className="shaddowletter">M</span><span className="shaddowletter">I</span><span className="shaddowletter">C</span><span className="shaddowletter">H</span>
          {/* <span className="subtitle">Darf ich mich vorstellen?</span> */}
        </p>
{/*         <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Über mich
        </h2> */}
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-7 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hallo, ich bin{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Ivo
              </span>
            </h2>
            <p className="text-5">
              Ich fotografiere für mein Leben gerne. Bereits als Kind fing ich <b>Momente</b> auf dem Pausenplatz ein, füllte einen Film nach dem Anderen und wartete (un)geduldig auf die Bilder aus dem Fotolabor. Seit einem Diashow-Kurs in der Mittelschule hat mich die Fotografie nicht mehr losgelassen.
              </p><p className="text-5">
Seit einigen Jahren bin ich als Fotograf an Hochzeiten und Events unterwegs. Nebst Aufträgen von einem Onlinemagazin, Ateliers (Modeschauen und Produktfotos) und Eventveranstalter habe ich vor ein paar Jahren ein Fotostudio aufgebaut. Hier konnte ich meine ersten Erfahrungen in der Portrait-Fotografie sammeln. Inzwischen fange ich meine <b>Bilder</b> aber lieber im Freien oder an einer speziellen Location ein. Dafür habe ich auch ein kleines mobiles Studio zur Verfügung.
 </p><p className="text-5">
Ich bin sehr froh darüber, dass meine zwei wundervollen Töchtern und meine Frau es mir ermöglichen, nebst meinem tollen Beruf als Software-Architekt, andere mit meinen Bildern glücklick zu machen. Ich liebe es, <b>Emotionen</b> einzufangen und beim späteren Betrachten wieder welche auszulösen. 
            </p> 
          </div>
          <div className="col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
                <div className="bg-light rounded-pill d-inline-block p-3 shadow-lg wow zoomIn">
                  <img
                    className="img-fluid rounded-pill d-block"
                    src="images/ivo.jpg"
                    title="I'm Ivo"
                    alt="I'm Ivo"
                  />
                </div>
              </div>
          
        </div>

      </div>
    </section>
  );
};

export default AboutUs;
