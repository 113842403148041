import "./App.scss";
import WOW from "wowjs";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Tooltip } from "./components/Tooltip";

import LandingWedding from "./components/LandingWedding";
import LandingFashion from "./components/LandingFashion";
import LandingTest from "./components/LandingTest";
import LandingMain from "./components/LandingMain";
import LandingVarianteA from "./components/LandingVarianteA";
import LandingVarianteB from "./components/LandingVarianteB";
import LandingVarianteC from "./components/LandingVarianteC";

function App() {


  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={


              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingMain />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
            } />

            {/* Landingpage Wedding */}
            <Route path="wedding" element={
              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingWedding />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    }}>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
             } />

            {/* Landingpage FASHION */}
            <Route path="fashion" element={
              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingFashion />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    }}>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
             } />

            {/* Landingpage TEST */}
            <Route path="test" element={
              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingTest />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    }}>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
             } />
      

            {/* Landingpage Variante A */}
            <Route path="variantea" element={
              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingVarianteA />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    }}>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
             } />

            {/* Landingpage Variante B */}
            <Route path="varianteb" element={
              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingVarianteB />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    }}>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
             } />

            {/* Landingpage Variante C */}
            <Route path="variantec" element={
              <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                  <LandingVarianteC />
                  <Footer handleNavClick={handleNavClick}></Footer>
                </div>

                {/* back to top */}
                <Tooltip text="Back to Top" placement="left">
                  <span
                    id="back-to-top"
                    className="rounded-circle"
                    style={{ display: scrollTopVisible ? "inline" : "none" }}
                    onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    }}>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Tooltip>
              </div>
             } />


          </Route>

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
