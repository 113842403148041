import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Astrid & Sämi",
      position: "Hochzeitspaar",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "“Ganz herzlichen Dank für deine fotografische Begleitung! Wir sind begeistert von den schönen Bildern.”",
    },
    {
      name: "Marlene & Stefan",
      position: "Hochzeitspaar",
      src: "images/testimonial/client-sm-2.jpg",
      desc: " “Wir haben aus den Bilder ein Fotoalbum gemacht. Das liegt bei uns immer auf dem Lounge-Tisch zum Blättern und in Erinnerungen schwelgen. Unsere Gäste und wir sind immer wieder begeistert von den tollen Momenen die du festgehalten hast.”",
    },
    {
      name: "D. & L.",
      position: "Hochzeitspaar aus Luzern",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "“Wow, wie schön die Bilder geworden sind. Vielen Dank.”",
    },
    {
      name: "Chris Tom",
      position: "Hochzeitspaar",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "“Wir und unsere Gäste haben uns sehr über die Bilder gefreut. Wir haben unglaublich viel darauf entdeckt. Deine diskrete und professionelle Art hat uns sehr gefallen. Unaufdringlich und immer zur rechten Zeit an Ort und Stelle. ”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-transparent p-2 sectionoverlap" >
      <div className="container bg-dark bg-gradient rounded-4">

{/*     <section id="testimonial" className="section bg-secondary">
      <div className="container"> */}

        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn pt-5">
          <span className="subtitle">Feedbacks meiner Kunden</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-3 wow fadeIn">
          Eure Meinungen
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp  pb-5">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
