import FullScreenHeader from "./themes/landingMain/HeaderFullScreenNav";
import FullScreenVideoBgIntro from "./themes/landingWedding/IntroAPVideoBg";

import AboutUs from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
/* import Testimonials from "./Testimonials"; */
import Contact from "./Contact";

const LandingWedding = () => {
    return (
        <>
            <FullScreenHeader textWhite={true}></FullScreenHeader>
            <FullScreenVideoBgIntro></FullScreenVideoBgIntro>
            <div id="content" role="main" className="bg-light">
                <AboutUs></AboutUs>
                <Services></Services>
                <Portfolio></Portfolio>
{/*                 <Testimonials></Testimonials> */}
                <Contact></Contact>
            </div>
        </>
        )
    
  };
  
  export default LandingWedding ;