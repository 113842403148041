import StandardMenuImgBgIntro from "./themes/varianteB/IntroImageBg";
import FullScreenHeader from "./themes/varianteB/Header";

import AboutUs from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

function LandingVarianteB() {


  return (
      <>
          <FullScreenHeader textWhite={true}></FullScreenHeader>
          <StandardMenuImgBgIntro></StandardMenuImgBgIntro>
          <div id="content" role="main">
          <AboutUs></AboutUs>
          <Services></Services>
          <Portfolio></Portfolio>
          <Testimonials></Testimonials>
          <Contact></Contact>
          </div>

      </>
      )
  
};

export default LandingVarianteB ;