import FullScreenHeader from "./themes/varianteA/Header";
import BottomHeaderVideoBgIntro from "./themes/varianteA/IntroVideoBg";

import AboutUs from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

function LandingVarianteA() {


    return (
        <>
            <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro> 
            <FullScreenHeader></FullScreenHeader>

            <div id="content" role="main">
            <AboutUs></AboutUs>
            <Services></Services>
            <Portfolio></Portfolio>
            <Testimonials></Testimonials>
            <Contact></Contact>
            </div>

        </>
        )
    
  };

  export default LandingVarianteA ;