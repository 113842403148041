import React from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import videobg from "../../../videos/home_fashion_background_low.mp4";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = [
  "Moment",
  "Picture",
  "Emotion"
];

const BottomHeaderVideoBgIntro = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-primary" />
        <div className="player hero-bg">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{
              width: "100%",
              height: "100vh",
              minHeight: "100%",
              objectFit: "cover",
            }}
          ></video>
        </div>
        <div className="hero-content d-flex flex-column fullscreen py-5">
          <div className="container my-auto">
            <div className="row">

            <div className="col-lg-5 text-center text-lg-start align-self-center order-0 order-lg-0">
                <div className="d-inline-block p-5 wow zoomIn">
                  <img
                    className="img-fluid d-block"
                    src="images/logo_weiss_transparent.png"
                    title="Arnet Photography"
                    alt="Logo Arnet Photography"
                  />
                </div>
              </div>


              <div className="col-lg-7 text-center text-lg-start align-self-center  order-1 order-lg-1">
              <h1 className="text-8 fw-300 mb-0 text-uppercase">
                  creator of
                </h1>
                <h2 className="text-20 fw-600 text-uppercase mb-0 ms-n1">

                        <TextTransition springConfig={presets.gentle} inline>
        {TEXTS[index % TEXTS.length]}
      </TextTransition>
                  S
                  {""}
                </h2>
                <p className="text-5"> </p>
                <Link
                  className="btn btn-dark rounded-0 smooth-scroll mt-3"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  to="portfolio"
                >
                  View My Works
                </Link>
                <Link
                  className="btn btn-link text-dark smooth-scroll mt-3"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  to="contact"
                >
                  Contact Me
                  <span className="text-4 ms-2">
                    <i className="far fa-arrow-alt-circle-down" />
                  </span>
                </Link>
              </div>


            </div>
          </div>
          <div className="container py-2 text-center">
            {" "}
            <Link
              className="scroll-down-arrow text-dark smooth-scroll"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="about"
            >
              <span className="animated">
                <i className="fas fa-arrow-down" />
              </span>
            </Link>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomHeaderVideoBgIntro;
