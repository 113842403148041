import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Portrait",
      desc: "Ob im Studio oder Draussen. Alleine, zu zweit oder als Familie. Gemeinsam finden wir eine tolle Kulisse. Und falls mal das Licht nicht reicht, bringe ich gerne mein mobiles Studio mit.",
      icon: "icon icon-portrait text-16",
    },
    {
      name: "Hochzeit",
      desc: "Lasst mich euren besonderen Moment für euch festhalten. Ich arbeite eng mit dem Hochzeitpaar und den Trauzeugen zusammen. So kann ich ganz gezielt auf die Wünsche und Besonderheiten eures Festes eingehen.",
      icon: "icon icon-wedding-rings text-16",
    },
    {
      name: "Mode & Event",
      desc: "Produkte und Lifestyle zum Ausdruck bringen. Euren Event in Bildern festhalten. Ich liebe es, die Spannung Backstage und die Show auf der Bühne festzuhalten. Die Leidenschaft für ein Handwerk.",
      icon: "icon icon-fashion-design text-16",
    },
    {
      name: "Mensch & Tier",
      desc: "Der beste Freund des Menschen. Oder auch gemeinsam mit dem Herrchen/Frauchen? Lasst mich euch mit eurem treuen Begleiter ablichten.",
      icon: "icon icon-dog-front text-16",
    },
    {
      name: "Drone",
      desc: "Neu im Angebot: Bilder und Videos aus der Luft. Für einmal eine ganz besondere Perspektive.",
      icon: "icon icon-drone-camera-1 text-16"
    },
    {
      name: "Website",
      desc: "Webseiten Entwicklung und Online-Vermarktung. Für ein umfängliches Konzept arbeite ich mit 360 Grad Media zusammen.",
      icon: "icon icon-internet text-15",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
        <span className="shaddowletter ">A</span><span className="shaddowletter">N</span><span className="shaddowletter">G</span><span className="shaddowletter">E</span><span className="shaddowletter">B</span><span className="shaddowletter">O</span><span className="shaddowletter">T</span>
          {/* <span className="subtitle">Wie kann ich Dich unterstützen?</span> */}
        </p>
{/*         <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Mein Angebot
        </h2> */}
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon gradient-icon text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
