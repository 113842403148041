export const filters = {
    FASHION: "Fashion",
    PORTRAIT:"Portrait",
    EVENT:"Event",
    WEDDING: "Hochzeit",
/*     YOUTUBE: "Videos"    */
  };

 export const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

export const projectsData = [
  {
    title: "Portrait Shooting with Dina",
    type: types.DOCUMENT,
    document: {
      projectInfo:
        "Foto-Shooting mit Dina auf dem Schiff und am Wasser auf einem kleinen Holzsteg.",
      client: "Dina",
      technologies: "Modelling, Fashion, Portrait",
      industry: "Fashion",
      date: "2023",
      url: {
        name: "Dina",
        link: "n/a",
      },

      sliderImages: [
        "images/projects/dina-2023-103.jpg",
        "images/projects/dina-2023-101.jpg",
        "images/projects/dina-2023-104.jpg",
      ],
    },

    thumbImage: "images/projects/dina-2023-102.jpg",

    categories: [filters.FASHION],
  },
  {
    title: "Portrait Shooting with Dina",
    type: types.DOCUMENT,
    document: {
      projectInfo:
        "Foto-Shooting mit Dina auf dem Schiff und am Wasser auf einem kleinen Holzsteg.",
      client: "Dina",
      technologies: "Modelling, Fashion, Portrait",
      industry: "Fashion",
      date: "2023",
      url: {
        name: "Dina",
        link: "n/a",
      },

      sliderImages: [
        "images/projects/dina-2023-302.jpg",
        "images/projects/dina-2023-303.jpg"
      ],
    },

    thumbImage: "images/projects/dina-2023-304.jpg",

    categories: [filters.FASHION],
  },  
  {
    title: "Portrait Shooting with Dina",
    type: types.DOCUMENT,
    document: {
      projectInfo:
        "Foto-Shooting mit Dina auf dem Schiff und am Wasser auf einem kleinen Holzsteg.",
      client: "Dina",
      technologies: "Modelling, Fashion, Portrait",
      industry: "Fashion",
      date: "2023",
      url: {
        name: "Dina",
        link: "n/a",
      },

      sliderImages: [
        "images/projects/dina-2023-203.jpg",
        "images/projects/dina-2023-202.jpg",
        "images/projects/dina-2023-211.jpg",
        "images/projects/dina-2023-212.jpg"
      ],
    },

    thumbImage: "images/projects/dina-2023-201.jpg",

    categories: [filters.FASHION],
  },
  {
    title: "Firenze",
    type: types.IMAGE,

    thumbImage: "images/projects/firenze-2023-371.jpg",

    categories: [filters.FASHION],
  },
  {
    title: "Firenze",
    type: types.IMAGE,

    thumbImage: "images/projects/firenze-2023-311.jpg",

    categories: [filters.FASHION],
  },
  {
    title: "Firenze",
    type: types.IMAGE,

    thumbImage: "images/projects/firenze-2023-121.jpg",

    categories: [filters.FASHION],
  },
  {
    title: "Firenze Theatre",
    type: types.DOCUMENT,
    document: {
      projectInfo:
        "Workshop in Firenze with Model Annalinda.",
      client: "Portfolio Upgrade",
      technologies: "Fashion, Lifestyle, Portrait",
      industry: "Fashion",
      date: "2023",


      sliderImages: [
        "images/projects/firenze-2023-112.jpg",
        "images/projects/firenze-2023-113.jpg",
      ],
    },

    thumbImage: "images/projects/firenze-2023-111.jpg",

    categories: [filters.FASHION],
  },
  {
    title: "Firenze",
    type: types.IMAGE,

    thumbImage: "images/projects/firenze-2023-211.jpg",

    categories: [filters.FASHION],
  },
    {
      title: "Product Catalog",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Produktfotos entstanden am Fashion Day for Generations im KKL in Luzern.",
        client: "Pack Easy",
        technologies: "Fashion, Product, Portrait",
        industry: "Fashion",
        date: "2018",
        url: {
          name: "www.packeasy.ch",
          link: "https://www.packeasy.ch",
        },

        sliderImages: [
          "images/projects/packeasy-001.jpg",
          "images/projects/packeasy-002.jpg",
        ],
      },

      thumbImage: "images/projects/packeasy-000.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Event",
      type: types.IMAGE,

      thumbImage: "https://drscdn.500px.org/photo/1066910741/m%3D900/v2?sig=08a77e06f2981698d9214e9b330e0b93019cae945504d8705f63b4a77f7be093",

      categories: [filters.EVENT],
    },
    {
      title: "Event",
      type: types.IMAGE,

      thumbImage: "images/projects/event-001.jpg",

      categories: [filters.EVENT],
    },
    {
      title: "Einladung Fashion Day 2023",
      type: types.IMAGE,

      thumbImage: "images/projects/lucouture-001.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Fashion Day",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-001.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Fashion Show Backstage",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-011.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-mia-001.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-mia-002.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-mia-003.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-mia-004.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-mia-005.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_011.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_012.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_013.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Familie",
      type: types.IMAGE,

      thumbImage: "images/projects/family-001.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-yourstyle-002.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-yourstyle-003.jpg",

      categories: [filters.PORTRAIT],
    },    
    {
      title: "Portrait Outdoor",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-outdoor-yourstyle-004.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Print Flyer",
      type: types.IMAGE,

      thumbImage: "images/projects/flyer_yourstyle_workshop.jpg",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Fashion Event",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-002.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Fashion Event",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-003.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_021.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_022.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_023.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_024.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-021.webp",

      categories: [filters.FASHION],
    },
    {
      title: "Fashion Event",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-031.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_031.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_032.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_033.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-041.webp",

      categories: [filters.FASHION],
    },    
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-042.webp",

      categories: [filters.FASHION],
    },    
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-043.webp",

      categories: [filters.FASHION],
    },  
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-044.webp",

      categories: [filters.FASHION],
    },       
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-045.webp",

      categories: [filters.FASHION],
    },    
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-046.webp",

      categories: [filters.FASHION],
    },  
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-047.webp",

      categories: [filters.FASHION],
    },              
    {
      title: "Fashion",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-048.webp",

      categories: [filters.FASHION],
    },         
    {
      title: "Portrait",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-studio-1275.webp",

      categories: [filters.PORTRAIT],
    },    
    {
      title: "Portrait",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-studio-1422.webp",

      categories: [filters.PORTRAIT],
    },      
    {
      title: "Portrait",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-studio-1468.webp",

      categories: [filters.PORTRAIT],
    },      
    {
      title: "Portrait",
      type: types.IMAGE,

      thumbImage: "images/projects/portrait-studio-2040.webp",

      categories: [filters.PORTRAIT],
    },      
    {
      title: "LP in Concert",
      type: types.IMAGE,

      thumbImage: "https://drscdn.500px.org/photo/1066920238/m%3D900/v2?sig=f85489d259712bf7e0cf0edb7476a8e855ff47e211d40ff37ef4898aab6eb8b7",

      categories: [filters.EVENT],
    },
    {
      title: "LP in Concert",
      type: types.IMAGE,

      thumbImage: "https://drscdn.500px.org/photo/1066920239/m%3D900/v2?sig=7de7e4f058d2f0a2b1ff80c75c98a5fa4171207316037b5fdda977d18f8447ac",

      categories: [filters.EVENT],
    },
    {
      title: "Revolverheld",
      type: types.IMAGE,

      thumbImage: "https://drscdn.500px.org/photo/1066920240/m%3D900/v2?sig=2433495a4d39da5fa811bad11892a6feccdba9f0a69ff04bb05daac1d0f0109c",

      categories: [filters.EVENT],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_041.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/lowkey-001.webp",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/lowkey-002.webp",

      categories: [filters.PORTRAIT],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_051.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Hochzeiten",
      type: types.IMAGE,

      thumbImage: "images/projects/wedding_052.jpg",

      categories: [filters.WEDDING],
    },
    {
      title: "Fashion Event",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-012.jpg",

      categories: [filters.FASHION],
    },
    {
      title: "Fashion Event",
      type: types.IMAGE,

      thumbImage: "images/projects/fashion-014.webp",

      categories: [filters.FASHION],
    },        
    {
        title: "Hochzeiten",
        type: types.IMAGE,
  
        thumbImage: "https://lh3.googleusercontent.com/pw/AJFCJaWInQ3xWHkpN5r6j30JGPeryLOSs6Tzwmtqada_wY_WBXbKr-vlXEuuQLWwsLXz1W2XZ34BO-bJmY1JBuZlfDu3eipGlgl7LVO8mU-u9nhFTiAjpImaMhFfAsInNPXieSLAiXfK-MHOx3uYFg71z1FK=w2675-h1784-s-no",
  
        categories: [filters.WEDDING],
      },
      {
        title: "Hochzeiten",
        type: types.IMAGE,
  
        thumbImage: "https://lh3.googleusercontent.com/pw/AJFCJaVHFz9KtQw8p1p_fX36letXUwDnxLG99-tTxBT8VYcR0QOxkUb03dXzjRUZlHgLe-_UfTvvevUsa3xyv-P2zUa4kmuhqNBtKaPxUY4zSyL-29lypUSIA_9E0G9NLZ1duMiZxoLkI8v9KdDf0BIPeSM1=w2048-h1365-s-no?authuser=0",
  
        categories: [filters.WEDDING],
      },  



      
/*     {
      title: "YouTube Video",
      type: types.VIDEO,
      video: {
        vimeo: false,
        id: "4CV_BtGbVFw",
      },
      thumbImage: "images/projects/project-3.jpg",

      categories: [filters.YOUTUBE],
    }, */
/*     {
      title: "Vimeo Video",
      type: types.VIDEO,
      video: {
        vimeo: true,
        id: "259411563",
      },

      thumbImage: "images/projects/project-4.jpg",
      categories: [filters.VIMEO],
    }, */
/*     {
      title: "Detailed Project 2",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        client: "Ruby Clinton",
        technologies: "iOS, HTML5, CSS3, PHP, Java",
        industry: "Art & Design",
        date: "July 16, 2019",
        url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },
        sliderImages: [
          "images/projects/project-1.jpg",
          "images/projects/project-2.jpg",
        ],
      },
      thumbImage: "images/projects/project-5.jpg",
      categories: [filters.DETAILED],
    }, */
  ];