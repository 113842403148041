import FullScreenHeader from "./themes/bottomHeader/Header";
import BottomHeaderVideoBgIntro from "./themes/bottomHeader/IntroVideoBg";

import AboutUs from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

function LandingTest() {


    return (
        <>
                     {/* 
                  <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>
                  <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>*/}
                  <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro> 
                  

                  {/* 
                  <BottomHeader></BottomHeader> 
                  
                  <FullScreenHeader
                    textWhite={appliedIntro === introBackgroundConfig.image}
                  ></FullScreenHeader>
                 
                  <StandardMenuHeader></StandardMenuHeader>
                  */}

            <FullScreenHeader></FullScreenHeader>
{/*              <FullScreenVideoBgIntro></FullScreenVideoBgIntro>  */}
            <div id="content" role="main">
            <AboutUs></AboutUs>
            <Services></Services>
            <Portfolio></Portfolio>
            <Testimonials></Testimonials>
            <Contact></Contact>
            </div>

        </>
        )
    
  };

  export default LandingTest ;