import React from "react";

const Partners = () => {

  const partner = [
    {
      name: "360 Media",
      imgurl: "images/partners/360media_logo_white.png",
    },
    {
      name: "Armin Nussbaumer",
      imgurl: "images/partners/arminnussbaumer_logo_white.png",
    },
  ];

  return (
    <section id="partners" className="section bg-gray">
      <div className="container">
        {/* Heading */}

        {/* Heading end*/}

        {/* My Skills */}
        <div className="row justify-content-evenly gx-5">
          {partner.length > 0 &&
            partner.map((partner, index) => (
              <div className="col-md-3 wow fadeInUp text-center" key={index}>
                <div>
                  <img
                    src={partner.imgurl}
                    alt={partner.name}
                    className="shadow p-3 mb-5 bg-secondary rounded"
                      
                    
                  />
                </div>
              </div>
            ))}
        </div>

      </div>
    </section>
  );
};

export default Partners;
